import {useEffect} from "react";
import useFetch from "v4/hooks/useFetch";

/**
 * @param {string} url
 * @param {React.DependencyList} dependencies
 * @return {{isLoading: boolean, isFinished: boolean, isError: boolean, data: Object, error: Object, errorData: Object}}
 */
export default function useAutoFetch(url, dependencies) {
    const [state, fetch] = useFetch();

    useEffect(() => {
        fetch({url});
    }, [fetch, url, dependencies]);

    return state;
}
